

export const state = () => ({
  profile: {}
})

export const getters = {
  profile: state => state.profile
}

export const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  }
}

export const actions = {
  getProfile({ commit }, { url, key }) {
    return this.$axios
      .get(url)
      .then(({ data }) => {
        commit('setProfile', key ? data[key] : data)
      })
  }
}