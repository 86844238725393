import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/auth/login","logout":"/auth/login","home":"/home","callback":"/home"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/","expires":1768532198618,"maxAge":31622400}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token.","global":true},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/login","method":"post","propertyName":"data.token"},"logout":{"url":"/logout","method":"post"},"user":{"url":"/me","method":"get","propertyName":"data"}},"token":{"property":"data.token","global":true,"required":true,"type":"Bearer","maxAge":31536000},"user":{"property":"data","autoFetch":true},"_name":"local"}))

  // bbm
  $auth.registerStrategy('bbm', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/dating/signin","method":"post","propertyName":"data.token"},"logout":{"url":"/logout","method":"post"},"user":{"url":"/me","method":"get","propertyName":"data"}},"token":{"property":"data.token","global":true,"required":true,"type":"Bearer","maxAge":31536000},"user":{"property":"data","autoFetch":true},"_name":"bbm"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
