import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _01d1c624 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _371221a7 = () => interopDefault(import('../pages/admin/index/index.vue' /* webpackChunkName: "pages/admin/index/index" */))
const _1f185c88 = () => interopDefault(import('../pages/admin/index/ads/index.vue' /* webpackChunkName: "pages/admin/index/ads/index" */))
const _fe483076 = () => interopDefault(import('../pages/admin/index/bbm/index.vue' /* webpackChunkName: "pages/admin/index/bbm/index" */))
const _3bda65c4 = () => interopDefault(import('../pages/admin/index/notify.vue' /* webpackChunkName: "pages/admin/index/notify" */))
const _4500cf5c = () => interopDefault(import('../pages/admin/index/pages/index.vue' /* webpackChunkName: "pages/admin/index/pages/index" */))
const _799dbee8 = () => interopDefault(import('../pages/admin/index/payments.vue' /* webpackChunkName: "pages/admin/index/payments" */))
const _176d112a = () => interopDefault(import('../pages/admin/index/payments/index.vue' /* webpackChunkName: "pages/admin/index/payments/index" */))
const _0b53f186 = () => interopDefault(import('../pages/admin/index/payments/bbm.vue' /* webpackChunkName: "pages/admin/index/payments/bbm" */))
const _5a25755d = () => interopDefault(import('../pages/admin/index/payments/pages.vue' /* webpackChunkName: "pages/admin/index/payments/pages" */))
const _59c599f8 = () => interopDefault(import('../pages/admin/index/payments/ushop.vue' /* webpackChunkName: "pages/admin/index/payments/ushop" */))
const _8390bb42 = () => interopDefault(import('../pages/admin/index/prompt.vue' /* webpackChunkName: "pages/admin/index/prompt" */))
const _5366d95a = () => interopDefault(import('../pages/admin/index/shippings.vue' /* webpackChunkName: "pages/admin/index/shippings" */))
const _5a09c7b1 = () => interopDefault(import('../pages/admin/index/shop.vue' /* webpackChunkName: "pages/admin/index/shop" */))
const _356033b5 = () => interopDefault(import('../pages/admin/index/shop/products/index.vue' /* webpackChunkName: "pages/admin/index/shop/products/index" */))
const _c34ae4ae = () => interopDefault(import('../pages/admin/index/shop/products/create.vue' /* webpackChunkName: "pages/admin/index/shop/products/create" */))
const _514795c6 = () => interopDefault(import('../pages/admin/index/shop/products/_product.vue' /* webpackChunkName: "pages/admin/index/shop/products/_product" */))
const _1144f776 = () => interopDefault(import('../pages/admin/index/withdraw.vue' /* webpackChunkName: "pages/admin/index/withdraw" */))
const _165b478d = () => interopDefault(import('../pages/admin/index/bbm/_uuid.vue' /* webpackChunkName: "pages/admin/index/bbm/_uuid" */))
const _f346e4ba = () => interopDefault(import('../pages/boxboxmo/index.vue' /* webpackChunkName: "pages/boxboxmo/index" */))
const _827d6b9e = () => interopDefault(import('../pages/channels/index.vue' /* webpackChunkName: "pages/channels/index" */))
const _0c66fa8e = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _013f22bd = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _e8b83100 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _1da1d08c = () => interopDefault(import('../pages/home/assistant.vue' /* webpackChunkName: "pages/home/assistant" */))
const _9a8a83e2 = () => interopDefault(import('../pages/home/assistant/index.vue' /* webpackChunkName: "pages/home/assistant/index" */))
const _675d62e2 = () => interopDefault(import('../pages/home/assistant/create.vue' /* webpackChunkName: "pages/home/assistant/create" */))
const _784e7284 = () => interopDefault(import('../pages/home/assistant/default.vue' /* webpackChunkName: "pages/home/assistant/default" */))
const _5b4aa890 = () => interopDefault(import('../pages/home/assistant/posts.vue' /* webpackChunkName: "pages/home/assistant/posts" */))
const _bceb3810 = () => interopDefault(import('../pages/home/assistant/_media.vue' /* webpackChunkName: "pages/home/assistant/_media" */))
const _36bd76da = () => interopDefault(import('../pages/home/me.vue' /* webpackChunkName: "pages/home/me" */))
const _27bcc2dd = () => interopDefault(import('../pages/home/password.vue' /* webpackChunkName: "pages/home/password" */))
const _bf00954c = () => interopDefault(import('../pages/home/signup.vue' /* webpackChunkName: "pages/home/signup" */))
const _f1bafa6e = () => interopDefault(import('../pages/home/channels/create.vue' /* webpackChunkName: "pages/home/channels/create" */))
const _173ae851 = () => interopDefault(import('../pages/home/channels/_channel.vue' /* webpackChunkName: "pages/home/channels/_channel" */))
const _04c9372c = () => interopDefault(import('../pages/home/posts/_id.vue' /* webpackChunkName: "pages/home/posts/_id" */))
const _73f46c30 = () => interopDefault(import('../pages/home/_id.vue' /* webpackChunkName: "pages/home/_id" */))
const _373d426b = () => interopDefault(import('../pages/home/_id/index.vue' /* webpackChunkName: "pages/home/_id/index" */))
const _4fc1549a = () => interopDefault(import('../pages/home/_id/create.vue' /* webpackChunkName: "pages/home/_id/create" */))
const _47a26e6a = () => interopDefault(import('../pages/home/_id/instagram.vue' /* webpackChunkName: "pages/home/_id/instagram" */))
const _b7089d1e = () => interopDefault(import('../pages/home/_id/instagram_poster.vue' /* webpackChunkName: "pages/home/_id/instagram_poster" */))
const _f210c5cc = () => interopDefault(import('../pages/home/_id/instagramwithfacebook/index.vue' /* webpackChunkName: "pages/home/_id/instagramwithfacebook/index" */))
const _1477b7a4 = () => interopDefault(import('../pages/home/_id/payments.vue' /* webpackChunkName: "pages/home/_id/payments" */))
const _e39a8720 = () => interopDefault(import('../pages/home/_id/pending.vue' /* webpackChunkName: "pages/home/_id/pending" */))
const _4065a628 = () => interopDefault(import('../pages/home/_id/posts.vue' /* webpackChunkName: "pages/home/_id/posts" */))
const _691d1395 = () => interopDefault(import('../pages/home/_id/preference/index.vue' /* webpackChunkName: "pages/home/_id/preference/index" */))
const _7d48fb48 = () => interopDefault(import('../pages/home/_id/private.vue' /* webpackChunkName: "pages/home/_id/private" */))
const _5769ecd5 = () => interopDefault(import('../pages/home/_id/schedules.vue' /* webpackChunkName: "pages/home/_id/schedules" */))
const _e0302ace = () => interopDefault(import('../pages/home/_id/styles.vue' /* webpackChunkName: "pages/home/_id/styles" */))
const _8008e4fc = () => interopDefault(import('../pages/home/_id/threads.vue' /* webpackChunkName: "pages/home/_id/threads" */))
const _4db56403 = () => interopDefault(import('../pages/home/_id/preference/autopublish.vue' /* webpackChunkName: "pages/home/_id/preference/autopublish" */))
const _7406e908 = () => interopDefault(import('../pages/home/_id/preference/binding.vue' /* webpackChunkName: "pages/home/_id/preference/binding" */))
const _71ed3fe1 = () => interopDefault(import('../pages/home/_id/preference/scheduling.vue' /* webpackChunkName: "pages/home/_id/preference/scheduling" */))
const _208e3c94 = () => interopDefault(import('../pages/home/_id/instagramwithfacebook/_page.vue' /* webpackChunkName: "pages/home/_id/instagramwithfacebook/_page" */))
const _53f1f30e = () => interopDefault(import('../pages/me/index.vue' /* webpackChunkName: "pages/me/index" */))
const _977bb834 = () => interopDefault(import('../pages/myshops/index.vue' /* webpackChunkName: "pages/myshops/index" */))
const _022b3796 = () => interopDefault(import('../pages/permissions.vue' /* webpackChunkName: "pages/permissions" */))
const _9785ef6c = () => interopDefault(import('../pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _985267c0 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _60f54e88 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _3b73f5ef = () => interopDefault(import('../pages/boxboxmo/chats/index.vue' /* webpackChunkName: "pages/boxboxmo/chats/index" */))
const _30025668 = () => interopDefault(import('../pages/boxboxmo/lgbt.vue' /* webpackChunkName: "pages/boxboxmo/lgbt" */))
const _69f574da = () => interopDefault(import('../pages/boxboxmo/me/index.vue' /* webpackChunkName: "pages/boxboxmo/me/index" */))
const _871b0bba = () => interopDefault(import('../pages/boxboxmo/put/index.vue' /* webpackChunkName: "pages/boxboxmo/put/index" */))
const _1ae76280 = () => interopDefault(import('../pages/boxboxmo/recharges/index.vue' /* webpackChunkName: "pages/boxboxmo/recharges/index" */))
const _9bac85d4 = () => interopDefault(import('../pages/boxboxmo/records/index.vue' /* webpackChunkName: "pages/boxboxmo/records/index" */))
const _a82f1088 = () => interopDefault(import('../pages/docs/instagram.vue' /* webpackChunkName: "pages/docs/instagram" */))
const _2cfdad12 = () => interopDefault(import('../pages/docs/privacy.vue' /* webpackChunkName: "pages/docs/privacy" */))
const _9d4e3178 = () => interopDefault(import('../pages/docs/services.vue' /* webpackChunkName: "pages/docs/services" */))
const _a1ebbeda = () => interopDefault(import('../pages/docs/tg_token.vue' /* webpackChunkName: "pages/docs/tg_token" */))
const _478644b3 = () => interopDefault(import('../pages/docs/threads.vue' /* webpackChunkName: "pages/docs/threads" */))
const _81b8fb78 = () => interopDefault(import('../pages/docs/tutorial.vue' /* webpackChunkName: "pages/docs/tutorial" */))
const _255c009f = () => interopDefault(import('../pages/me/shop.vue' /* webpackChunkName: "pages/me/shop" */))
const _c1b1583c = () => interopDefault(import('../pages/me/shop/index.vue' /* webpackChunkName: "pages/me/shop/index" */))
const _21b6e8a4 = () => interopDefault(import('../pages/me/shop/dashboard.vue' /* webpackChunkName: "pages/me/shop/dashboard" */))
const _01f36748 = () => interopDefault(import('../pages/me/shop/orders/index.vue' /* webpackChunkName: "pages/me/shop/orders/index" */))
const _8c197df2 = () => interopDefault(import('../pages/me/shop/products/index.vue' /* webpackChunkName: "pages/me/shop/products/index" */))
const _a290a99c = () => interopDefault(import('../pages/boxboxmo/me/edit.vue' /* webpackChunkName: "pages/boxboxmo/me/edit" */))
const _9e5ca318 = () => interopDefault(import('../pages/boxboxmo/me/invite/index.vue' /* webpackChunkName: "pages/boxboxmo/me/invite/index" */))
const _4926ff9c = () => interopDefault(import('../pages/boxboxmo/me/withdraw.vue' /* webpackChunkName: "pages/boxboxmo/me/withdraw" */))
const _7ae27864 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _72b04258 = () => interopDefault(import('../pages/boxboxmo/chats/_chat.vue' /* webpackChunkName: "pages/boxboxmo/chats/_chat" */))
const _829c321e = () => interopDefault(import('../pages/boxboxmo/put/_no.vue' /* webpackChunkName: "pages/boxboxmo/put/_no" */))
const _30fb8264 = () => interopDefault(import('../pages/boxboxmo/recharges/_no.vue' /* webpackChunkName: "pages/boxboxmo/recharges/_no" */))
const _9b52e4d2 = () => interopDefault(import('../pages/boxboxmo/take/_no.vue' /* webpackChunkName: "pages/boxboxmo/take/_no" */))
const _cee86410 = () => interopDefault(import('../pages/channels/_channel/index.vue' /* webpackChunkName: "pages/channels/_channel/index" */))
const _210c78ea = () => interopDefault(import('../pages/feed/_id/index.vue' /* webpackChunkName: "pages/feed/_id/index" */))
const _d4cec7d8 = () => interopDefault(import('../pages/me/_user/index.vue' /* webpackChunkName: "pages/me/_user/index" */))
const _5597552e = () => interopDefault(import('../pages/myshops/_shop.vue' /* webpackChunkName: "pages/myshops/_shop" */))
const _561b692c = () => interopDefault(import('../pages/myshops/_shop/index.vue' /* webpackChunkName: "pages/myshops/_shop/index" */))
const _fd0f4ce6 = () => interopDefault(import('../pages/myshops/_shop/ads/index.vue' /* webpackChunkName: "pages/myshops/_shop/ads/index" */))
const _102ec790 = () => interopDefault(import('../pages/myshops/_shop/assistant.vue' /* webpackChunkName: "pages/myshops/_shop/assistant" */))
const _7e29a70c = () => interopDefault(import('../pages/myshops/_shop/instagram.vue' /* webpackChunkName: "pages/myshops/_shop/instagram" */))
const _5f76aa84 = () => interopDefault(import('../pages/myshops/_shop/orders/index.vue' /* webpackChunkName: "pages/myshops/_shop/orders/index" */))
const _e6e1480c = () => interopDefault(import('../pages/myshops/_shop/products.vue' /* webpackChunkName: "pages/myshops/_shop/products" */))
const _39017efd = () => interopDefault(import('../pages/myshops/_shop/products/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/index" */))
const _271f5878 = () => interopDefault(import('../pages/myshops/_shop/products/categories/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/categories/index" */))
const _3c557732 = () => interopDefault(import('../pages/myshops/_shop/products/poster.vue' /* webpackChunkName: "pages/myshops/_shop/products/poster" */))
const _3a59b168 = () => interopDefault(import('../pages/myshops/_shop/products/variations/index.vue' /* webpackChunkName: "pages/myshops/_shop/products/variations/index" */))
const _4653c4d5 = () => interopDefault(import('../pages/myshops/_shop/products/_product.vue' /* webpackChunkName: "pages/myshops/_shop/products/_product" */))
const _6526da0d = () => interopDefault(import('../pages/myshops/_shop/promocodes.vue' /* webpackChunkName: "pages/myshops/_shop/promocodes" */))
const _247db72a = () => interopDefault(import('../pages/myshops/_shop/setting.vue' /* webpackChunkName: "pages/myshops/_shop/setting" */))
const _68aab62d = () => interopDefault(import('../pages/myshops/_shop/setting/index.vue' /* webpackChunkName: "pages/myshops/_shop/setting/index" */))
const _90a987c8 = () => interopDefault(import('../pages/myshops/_shop/setting/groupbuy.vue' /* webpackChunkName: "pages/myshops/_shop/setting/groupbuy" */))
const _234ee8e6 = () => interopDefault(import('../pages/myshops/_shop/setting/instagram.vue' /* webpackChunkName: "pages/myshops/_shop/setting/instagram" */))
const _9b85a732 = () => interopDefault(import('../pages/myshops/_shop/setting/off_day.vue' /* webpackChunkName: "pages/myshops/_shop/setting/off_day" */))
const _fd1512ae = () => interopDefault(import('../pages/myshops/_shop/setting/order.vue' /* webpackChunkName: "pages/myshops/_shop/setting/order" */))
const _3d053e2c = () => interopDefault(import('../pages/myshops/_shop/setting/order_cut.vue' /* webpackChunkName: "pages/myshops/_shop/setting/order_cut" */))
const _095d3aa3 = () => interopDefault(import('../pages/myshops/_shop/setting/shipping.vue' /* webpackChunkName: "pages/myshops/_shop/setting/shipping" */))
const _6f7dc3f4 = () => interopDefault(import('../pages/myshops/_shop/setting/sms.vue' /* webpackChunkName: "pages/myshops/_shop/setting/sms" */))
const _0467238c = () => interopDefault(import('../pages/myshops/_shop/setting/style.vue' /* webpackChunkName: "pages/myshops/_shop/setting/style" */))
const _36a69500 = () => interopDefault(import('../pages/myshops/_shop/summary.vue' /* webpackChunkName: "pages/myshops/_shop/summary" */))
const _c35b40da = () => interopDefault(import('../pages/myshops/_shop/orders/_order.vue' /* webpackChunkName: "pages/myshops/_shop/orders/_order" */))
const _677fa87c = () => interopDefault(import('../pages/passcode/_username.vue' /* webpackChunkName: "pages/passcode/_username" */))
const _70fcb1e4 = () => interopDefault(import('../pages/s/_shop/index.vue' /* webpackChunkName: "pages/s/_shop/index" */))
const _ba3bafc4 = () => interopDefault(import('../pages/feed/_id/payment.vue' /* webpackChunkName: "pages/feed/_id/payment" */))
const _69b01ab5 = () => interopDefault(import('../pages/feed/_id/vip.vue' /* webpackChunkName: "pages/feed/_id/vip" */))
const _663e5f8f = () => interopDefault(import('../pages/s/_shop/me/index.vue' /* webpackChunkName: "pages/s/_shop/me/index" */))
const _7c996a9c = () => interopDefault(import('../pages/s/_shop/orders/index.vue' /* webpackChunkName: "pages/s/_shop/orders/index" */))
const _07a35f6c = () => interopDefault(import('../pages/s/_shop/orders/checkout.vue' /* webpackChunkName: "pages/s/_shop/orders/checkout" */))
const _33d32221 = () => interopDefault(import('../pages/channels/_channel/topics/_topic.vue' /* webpackChunkName: "pages/channels/_channel/topics/_topic" */))
const _7c959b75 = () => interopDefault(import('../pages/s/_shop/orders/_order.vue' /* webpackChunkName: "pages/s/_shop/orders/_order" */))
const _d21b8092 = () => interopDefault(import('../pages/s/_shop/products/_product.vue' /* webpackChunkName: "pages/s/_shop/products/_product" */))
const _2b17fbdc = () => interopDefault(import('../pages/me/_user/_uuid.vue' /* webpackChunkName: "pages/me/_user/_uuid" */))
const _2129e21b = () => interopDefault(import('../pages/s/_shop/_.vue' /* webpackChunkName: "pages/s/_shop/_" */))
const _5140c14f = () => interopDefault(import('../pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _7ccd3f37 = () => interopDefault(import('../pages/_id/destroy.vue' /* webpackChunkName: "pages/_id/destroy" */))
const _0c5ea860 = () => interopDefault(import('../pages/_id/posts.vue' /* webpackChunkName: "pages/_id/posts" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _01d1c624,
    children: [{
      path: "",
      component: _371221a7,
      name: "admin-index___zh-mo"
    }, {
      path: "ads",
      component: _1f185c88,
      name: "admin-index-ads___zh-mo"
    }, {
      path: "bbm",
      component: _fe483076,
      name: "admin-index-bbm___zh-mo"
    }, {
      path: "notify",
      component: _3bda65c4,
      name: "admin-index-notify___zh-mo"
    }, {
      path: "pages",
      component: _4500cf5c,
      name: "admin-index-pages___zh-mo"
    }, {
      path: "payments",
      component: _799dbee8,
      children: [{
        path: "",
        component: _176d112a,
        name: "admin-index-payments___zh-mo"
      }, {
        path: "bbm",
        component: _0b53f186,
        name: "admin-index-payments-bbm___zh-mo"
      }, {
        path: "pages",
        component: _5a25755d,
        name: "admin-index-payments-pages___zh-mo"
      }, {
        path: "ushop",
        component: _59c599f8,
        name: "admin-index-payments-ushop___zh-mo"
      }]
    }, {
      path: "prompt",
      component: _8390bb42,
      name: "admin-index-prompt___zh-mo"
    }, {
      path: "shippings",
      component: _5366d95a,
      name: "admin-index-shippings___zh-mo"
    }, {
      path: "shop",
      component: _5a09c7b1,
      name: "admin-index-shop___zh-mo",
      children: [{
        path: "products",
        component: _356033b5,
        name: "admin-index-shop-products___zh-mo"
      }, {
        path: "products/create",
        component: _c34ae4ae,
        name: "admin-index-shop-products-create___zh-mo"
      }, {
        path: "products/:product",
        component: _514795c6,
        name: "admin-index-shop-products-product___zh-mo"
      }]
    }, {
      path: "withdraw",
      component: _1144f776,
      name: "admin-index-withdraw___zh-mo"
    }, {
      path: "bbm/:uuid?",
      component: _165b478d,
      name: "admin-index-bbm-uuid___zh-mo"
    }]
  }, {
    path: "/boxboxmo",
    component: _f346e4ba,
    name: "boxboxmo___zh-mo"
  }, {
    path: "/channels",
    component: _827d6b9e,
    name: "channels___zh-mo"
  }, {
    path: "/chat",
    component: _0c66fa8e,
    name: "chat___zh-mo"
  }, {
    path: "/home",
    component: _013f22bd,
    children: [{
      path: "",
      component: _e8b83100,
      name: "home___zh-mo"
    }, {
      path: "assistant",
      component: _1da1d08c,
      children: [{
        path: "",
        component: _9a8a83e2,
        name: "home-assistant___zh-mo"
      }, {
        path: "create",
        component: _675d62e2,
        name: "home-assistant-create___zh-mo"
      }, {
        path: "default",
        component: _784e7284,
        name: "home-assistant-default___zh-mo"
      }, {
        path: "posts",
        component: _5b4aa890,
        name: "home-assistant-posts___zh-mo"
      }, {
        path: ":media",
        component: _bceb3810,
        name: "home-assistant-media___zh-mo"
      }]
    }, {
      path: "me",
      component: _36bd76da,
      name: "home-me___zh-mo"
    }, {
      path: "password",
      component: _27bcc2dd,
      name: "home-password___zh-mo"
    }, {
      path: "signup",
      component: _bf00954c,
      name: "home-signup___zh-mo"
    }, {
      path: "channels/create",
      component: _f1bafa6e,
      name: "home-channels-create___zh-mo"
    }, {
      path: "channels/:channel?",
      component: _173ae851,
      name: "home-channels-channel___zh-mo"
    }, {
      path: "posts/:id?",
      component: _04c9372c,
      name: "home-posts-id___zh-mo"
    }, {
      path: ":id",
      component: _73f46c30,
      children: [{
        path: "",
        component: _373d426b,
        name: "home-id___zh-mo"
      }, {
        path: "create",
        component: _4fc1549a,
        name: "home-id-create___zh-mo"
      }, {
        path: "instagram",
        component: _47a26e6a,
        name: "home-id-instagram___zh-mo"
      }, {
        path: "instagram_poster",
        component: _b7089d1e,
        name: "home-id-instagram_poster___zh-mo"
      }, {
        path: "instagramwithfacebook",
        component: _f210c5cc,
        name: "home-id-instagramwithfacebook___zh-mo"
      }, {
        path: "payments",
        component: _1477b7a4,
        name: "home-id-payments___zh-mo"
      }, {
        path: "pending",
        component: _e39a8720,
        name: "home-id-pending___zh-mo"
      }, {
        path: "posts",
        component: _4065a628,
        name: "home-id-posts___zh-mo"
      }, {
        path: "preference",
        component: _691d1395,
        name: "home-id-preference___zh-mo"
      }, {
        path: "private",
        component: _7d48fb48,
        name: "home-id-private___zh-mo"
      }, {
        path: "schedules",
        component: _5769ecd5,
        name: "home-id-schedules___zh-mo"
      }, {
        path: "styles",
        component: _e0302ace,
        name: "home-id-styles___zh-mo"
      }, {
        path: "threads",
        component: _8008e4fc,
        name: "home-id-threads___zh-mo"
      }, {
        path: "preference/autopublish",
        component: _4db56403,
        name: "home-id-preference-autopublish___zh-mo"
      }, {
        path: "preference/binding",
        component: _7406e908,
        name: "home-id-preference-binding___zh-mo"
      }, {
        path: "preference/scheduling",
        component: _71ed3fe1,
        name: "home-id-preference-scheduling___zh-mo"
      }, {
        path: "instagramwithfacebook/:page",
        component: _208e3c94,
        name: "home-id-instagramwithfacebook-page___zh-mo"
      }]
    }]
  }, {
    path: "/me",
    component: _53f1f30e,
    name: "me___zh-mo"
  }, {
    path: "/myshops",
    component: _977bb834,
    name: "myshops___zh-mo"
  }, {
    path: "/permissions",
    component: _022b3796,
    name: "permissions___zh-mo"
  }, {
    path: "/receipt",
    component: _9785ef6c,
    name: "receipt___zh-mo"
  }, {
    path: "/auth/login",
    component: _985267c0,
    name: "auth-login___zh-mo"
  }, {
    path: "/auth/register",
    component: _60f54e88,
    name: "auth-register___zh-mo"
  }, {
    path: "/boxboxmo/chats",
    component: _3b73f5ef,
    name: "boxboxmo-chats___zh-mo"
  }, {
    path: "/boxboxmo/lgbt",
    component: _30025668,
    name: "boxboxmo-lgbt___zh-mo"
  }, {
    path: "/boxboxmo/me",
    component: _69f574da,
    name: "boxboxmo-me___zh-mo"
  }, {
    path: "/boxboxmo/put",
    component: _871b0bba,
    name: "boxboxmo-put___zh-mo"
  }, {
    path: "/boxboxmo/recharges",
    component: _1ae76280,
    name: "boxboxmo-recharges___zh-mo"
  }, {
    path: "/boxboxmo/records",
    component: _9bac85d4,
    name: "boxboxmo-records___zh-mo"
  }, {
    path: "/docs/instagram",
    component: _a82f1088,
    name: "docs-instagram___zh-mo"
  }, {
    path: "/docs/privacy",
    component: _2cfdad12,
    name: "docs-privacy___zh-mo"
  }, {
    path: "/docs/services",
    component: _9d4e3178,
    name: "docs-services___zh-mo"
  }, {
    path: "/docs/tg_token",
    component: _a1ebbeda,
    name: "docs-tg_token___zh-mo"
  }, {
    path: "/docs/threads",
    component: _478644b3,
    name: "docs-threads___zh-mo"
  }, {
    path: "/docs/tutorial",
    component: _81b8fb78,
    name: "docs-tutorial___zh-mo"
  }, {
    path: "/me/shop",
    component: _255c009f,
    children: [{
      path: "",
      component: _c1b1583c,
      name: "me-shop___zh-mo"
    }, {
      path: "dashboard",
      component: _21b6e8a4,
      name: "me-shop-dashboard___zh-mo"
    }, {
      path: "orders",
      component: _01f36748,
      name: "me-shop-orders___zh-mo"
    }, {
      path: "products",
      component: _8c197df2,
      name: "me-shop-products___zh-mo"
    }]
  }, {
    path: "/boxboxmo/me/edit",
    component: _a290a99c,
    name: "boxboxmo-me-edit___zh-mo"
  }, {
    path: "/boxboxmo/me/invite",
    component: _9e5ca318,
    name: "boxboxmo-me-invite___zh-mo"
  }, {
    path: "/boxboxmo/me/withdraw",
    component: _4926ff9c,
    name: "boxboxmo-me-withdraw___zh-mo"
  }, {
    path: "/",
    component: _7ae27864,
    name: "index___zh-mo"
  }, {
    path: "/boxboxmo/chats/:chat",
    component: _72b04258,
    name: "boxboxmo-chats-chat___zh-mo"
  }, {
    path: "/boxboxmo/put/:no",
    component: _829c321e,
    name: "boxboxmo-put-no___zh-mo"
  }, {
    path: "/boxboxmo/recharges/:no",
    component: _30fb8264,
    name: "boxboxmo-recharges-no___zh-mo"
  }, {
    path: "/boxboxmo/take/:no?",
    component: _9b52e4d2,
    name: "boxboxmo-take-no___zh-mo"
  }, {
    path: "/channels/:channel",
    component: _cee86410,
    name: "channels-channel___zh-mo"
  }, {
    path: "/feed/:id",
    component: _210c78ea,
    name: "feed-id___zh-mo"
  }, {
    path: "/me/:user",
    component: _d4cec7d8,
    name: "me-user___zh-mo"
  }, {
    path: "/myshops/:shop",
    component: _5597552e,
    children: [{
      path: "",
      component: _561b692c,
      name: "myshops-shop___zh-mo"
    }, {
      path: "ads",
      component: _fd0f4ce6,
      name: "myshops-shop-ads___zh-mo"
    }, {
      path: "assistant",
      component: _102ec790,
      name: "myshops-shop-assistant___zh-mo"
    }, {
      path: "instagram",
      component: _7e29a70c,
      name: "myshops-shop-instagram___zh-mo"
    }, {
      path: "orders",
      component: _5f76aa84,
      name: "myshops-shop-orders___zh-mo"
    }, {
      path: "products",
      component: _e6e1480c,
      children: [{
        path: "",
        component: _39017efd,
        name: "myshops-shop-products___zh-mo"
      }, {
        path: "categories",
        component: _271f5878,
        name: "myshops-shop-products-categories___zh-mo"
      }, {
        path: "poster",
        component: _3c557732,
        name: "myshops-shop-products-poster___zh-mo"
      }, {
        path: "variations",
        component: _3a59b168,
        name: "myshops-shop-products-variations___zh-mo"
      }, {
        path: ":product",
        component: _4653c4d5,
        name: "myshops-shop-products-product___zh-mo"
      }]
    }, {
      path: "promocodes",
      component: _6526da0d,
      name: "myshops-shop-promocodes___zh-mo"
    }, {
      path: "setting",
      component: _247db72a,
      children: [{
        path: "",
        component: _68aab62d,
        name: "myshops-shop-setting___zh-mo"
      }, {
        path: "groupbuy",
        component: _90a987c8,
        name: "myshops-shop-setting-groupbuy___zh-mo"
      }, {
        path: "instagram",
        component: _234ee8e6,
        name: "myshops-shop-setting-instagram___zh-mo"
      }, {
        path: "off_day",
        component: _9b85a732,
        name: "myshops-shop-setting-off_day___zh-mo"
      }, {
        path: "order",
        component: _fd1512ae,
        name: "myshops-shop-setting-order___zh-mo"
      }, {
        path: "order_cut",
        component: _3d053e2c,
        name: "myshops-shop-setting-order_cut___zh-mo"
      }, {
        path: "shipping",
        component: _095d3aa3,
        name: "myshops-shop-setting-shipping___zh-mo"
      }, {
        path: "sms",
        component: _6f7dc3f4,
        name: "myshops-shop-setting-sms___zh-mo"
      }, {
        path: "style",
        component: _0467238c,
        name: "myshops-shop-setting-style___zh-mo"
      }]
    }, {
      path: "summary",
      component: _36a69500,
      name: "myshops-shop-summary___zh-mo"
    }, {
      path: "orders/:order",
      component: _c35b40da,
      name: "myshops-shop-orders-order___zh-mo"
    }]
  }, {
    path: "/passcode/:username?",
    component: _677fa87c,
    name: "passcode-username___zh-mo"
  }, {
    path: "/s/:shop",
    component: _70fcb1e4,
    name: "s-shop___zh-mo"
  }, {
    path: "/feed/:id?/payment",
    component: _ba3bafc4,
    name: "feed-id-payment___zh-mo"
  }, {
    path: "/feed/:id?/vip",
    component: _69b01ab5,
    name: "feed-id-vip___zh-mo"
  }, {
    path: "/s/:shop?/me",
    component: _663e5f8f,
    name: "s-shop-me___zh-mo"
  }, {
    path: "/s/:shop?/orders",
    component: _7c996a9c,
    name: "s-shop-orders___zh-mo"
  }, {
    path: "/s/:shop?/orders/checkout",
    component: _07a35f6c,
    name: "s-shop-orders-checkout___zh-mo"
  }, {
    path: "/channels/:channel/topics/:topic?",
    component: _33d32221,
    name: "channels-channel-topics-topic___zh-mo"
  }, {
    path: "/s/:shop?/orders/:order",
    component: _7c959b75,
    name: "s-shop-orders-order___zh-mo"
  }, {
    path: "/s/:shop?/products/:product?",
    component: _d21b8092,
    name: "s-shop-products-product___zh-mo"
  }, {
    path: "/me/:user/:uuid",
    component: _2b17fbdc,
    name: "me-user-uuid___zh-mo"
  }, {
    path: "/s/:shop?/*",
    component: _2129e21b,
    name: "s-shop-all___zh-mo"
  }, {
    path: "/:id",
    component: _5140c14f,
    name: "id___zh-mo"
  }, {
    path: "/:id/destroy",
    component: _7ccd3f37,
    name: "id-destroy___zh-mo"
  }, {
    path: "/:id/posts",
    component: _0c5ea860,
    name: "id-posts___zh-mo"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
